.team-comp-container img {
  filter: drop-shadow(0 0 0.7rem #eadaff80);
  transition: 300ms;
  }

  .team-comp-container img:hover {
    filter: drop-shadow(0 0 0.75rem #eadaff);
    }

.team-comp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.team-comp {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 25px;
  width: 100%;
  max-width: 800px;
  gap: 4rem;
}

.team-comp-left,
.team-comp-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
}

.team-comp-left {
  flex: 1;
  text-align: center;
}

.team-logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.team-name {
  font-size: 24px;
  font-weight: bold;
  color: var(--skygreen);
  margin-top: -10px;
}

.team-description {
  font-size: 16px;
  color: var(--skypink);
  font-weight: 500;
}

.team-comp-right {
  flex: 1;
}

.team-projects {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.team-project {
  border-radius: 20px;
  padding: 10px 10px;
  color: var(--skypink);
  font-size: 16px;
  text-align: center;
  border: 1px solid var(--skygreen);
}

.team-comp.left .team-comp-left {
  order: 1;
}

.team-comp.left .team-comp-right {
  order: 2;
}

.team-comp.right .team-comp-left {
  order: 2;
}

.team-comp.right .team-comp-right {
  order: 1;
}

.team-button{
  display: flex;
  justify-content: center;
  color: var(--skypink);
  text-decoration: none;
  border-radius: 20px;
  max-width: 50%;
  margin: auto;
}

.team-button a:hover{
  cursor: pointer;
  filter: drop-shadow(0 0 0.7rem #eadaff80);
}

.team-button a{
  color: var(--skypink);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: var(--skygreen);
  transition: 200ms ease-out;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
    .team-comp-container{
      gap: 3rem;
    }

    .team-comp {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    
    .team-comp-left,
    .team-comp-right {
      width: 100%;
      order: initial; 
    }
    
    .team-projects {
      width: 100%;
      gap: 1rem;
    }
  
    .team-comp.left .team-comp-left,
    .team-comp.left .team-comp-right,
    .team-comp.right .team-comp-left,
    .team-comp.right .team-comp-right {
      order: initial; 
    }

    .team-comp.left{
        padding: 0;
    }

    

    .team-comp.right{
        padding: 0;
    }

    
  }