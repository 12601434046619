.team-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

.team-detail-container img{
  filter: drop-shadow(0 0 0.7rem #eadaff80);
}
  
  .team-detail-container .team-logo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-size: cover;
    margin-bottom: 20px;
  }
  
  .team-detail-container .team-name {
    font-size: 40px;
    font-weight: bold;
    color: var(--skygreen);
    margin-bottom: 20px;
  }
  
  .team-detail-container .team-description {
    font-size: 18px;
    color: var(--skypink);
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
    max-width: 600px;
  }
  
  .team-detail-container .team-projects {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .team-detail-container .team-project {
    border-radius: 20px;
    padding: 10px 10px;
    color: var(--skypink);
    font-size: 16px;
    text-align: center;
    border: 1px solid var(--skygreen);
  }