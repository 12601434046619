.skylab-logo{
    height: 100px;
    width: auto;
    padding: 3rem;
}

.App{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px){
    .skylab-logo{
        height: 60px;
        width: auto;
        padding: 3rem 0;
    }
}